import React, { useState, useEffect } from 'react';
import api from '../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';

const JobApplicants = ({ jobId, onSelectInfluencer }) => {
  const [applications, setApplications] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchApplications = async () => {
      setIsLoading(true);
      try {
        const res = await api.get(`/jobs/${jobId}/applicants`);
        setApplications(res.data);
      } catch (err) {
        setError(err.response?.data?.msg || 'Failed to load applicants.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchApplications();
  }, [jobId]);

  const handleApprove = async (applicationId) => {
    try {
      await api.put(`/applications/${applicationId}/status`, { status: 'Approved' });
      setApplications((prev) =>
        prev.map((app) =>
          app._id === applicationId ? { ...app, status: 'Approved' } : app
        )
      );
    } catch (err) {
      console.error('Error approving application:', err);
      alert('Failed to approve the application.');
    }
  };

  const handleReject = async (applicationId) => {
    try {
      await api.put(`/applications/${applicationId}/status`, { status: 'Rejected' });
      setApplications((prev) =>
        prev.map((app) =>
          app._id === applicationId ? { ...app, status: 'Rejected' } : app
        )
      );
    } catch (err) {
      console.error('Error rejecting application:', err);
      alert('Failed to reject the application.');
    }
  };

  if (isLoading) {
    return <div>Loading applications...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (applications.length === 0) {
    return <div>No applicants yet.</div>;
  }

  return (
    <div>
      <h3 className="text-xl font-semibold mb-2">Applicants</h3>
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Influencer Name</th>
            <th className="py-2 px-4 border-b">Status</th>
            <th className="py-2 px-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {applications.map((application) => (
            <tr key={application._id} className="text-center">
              <td className="py-2 px-4 border-b">{application.influencer?.name || 'Unknown Influencer'}</td>
              <td className="py-2 px-4 border-b">{application.status}</td>
              <td className="py-2 px-4 border-b space-x-2">
                {application.status === 'Pending' && (
                  <>
                    <button
                      onClick={() => handleApprove(application._id)}
                      className="bg-green-500 hover:bg-green-600 text-white py-1 px-3 rounded"
                    >
                      Approve
                    </button>
                    <button
                      onClick={() => handleReject(application._id)}
                      className="bg-red-500 hover:bg-red-600 text-white py-1 px-3 rounded"
                    >
                      Reject
                    </button>
                  </>
                )}
                {application.status === 'Approved' && (
                  <>
                    <button
                      onClick={() => onSelectInfluencer(application.influencer)}
                      className="text-blue-500 hover:text-blue-700"
                      title="Chat with Influencer (1 Credit Per Message)"
                    >
                      <FontAwesomeIcon icon={faComments} size="lg" />
                    </button>
                    <span className="text-sm text-gray-500">1 credit/message</span>
                  </>
                )}
                {application.status === 'Rejected' && <span>Rejected</span>}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default JobApplicants;
