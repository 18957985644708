import React, { useState, useEffect } from 'react';
import { FaBell, FaEnvelope, FaSearch } from 'react-icons/fa';
import { useNavigate, Outlet } from 'react-router-dom';
import AccountDetailsModal from './components/AccountDetailsModal';
import Sidebar from './components/Sidebar';
import api from './api';
import { jwtDecode } from 'jwt-decode'; // Correct import
import ChatWindow from './components/ChatWindow';


  

const token = localStorage.getItem('token');


const DashboardLayout = ( receiverId, jobId) => {
  const [isAccountModalOpen, setAccountModalOpen] = useState(false);
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [isNotificationDropdownVisible, setNotificationDropdownVisible] = useState(false);
  const [userId, setUserId] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const handleIconClick = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        console.log("Decoded Token:", decodedToken); // Debug
        if (decodedToken.user?.id) {
          setUserId(decodedToken.user.id); // Set the user ID
        } else {
          console.error("No user ID found in token");
          navigate('/login'); // Redirect if ID is missing
        }
      } catch (err) {
        console.error("Error decoding token:", err);
        navigate('/login'); // Redirect on error
      }
    } else {
      navigate('/login'); // Redirect if token is missing
    }
  }, [token, navigate]);
  

  const handleNotificationClick = async () => {
    console.log("Notification icon clicked");
    console.log("isNotificationDropdownVisible:", isNotificationDropdownVisible);
    console.log("userId:", userId);
  
    if (!userId) {
      console.error("User ID is undefined, cannot fetch notifications");
      return;
    }
  
    try {
      if (!isNotificationDropdownVisible) {
        console.log("Sending API request for notifications...");
        const response = await api.get(`/notifications`, {
          params: { userId },
        });
        console.log("API Response:", response.data);
        setNotifications(response.data);
      }
      setNotificationDropdownVisible(!isNotificationDropdownVisible);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };
  
  
  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <div className="min-h-screen flex">
      <Sidebar />

      <div className="flex-1 flex flex-col">
        <header className="bg-white shadow-md py-4 px-6 flex items-center justify-between">
          <h1 className="text-2xl font-bold">Brand Dashboard</h1>

          <div className="flex items-center">
            <input
              type="text"
              placeholder="Search Influencers"
              className="rounded-md border border-gray-300 py-2 px-4 w-64 mr-4"
            />
            <FaSearch className="text-gray-500" />
          </div>

          <div className="flex items-center space-x-6 relative">
            <div className="relative">
              <FaBell
                className="text-gray-500 hover:text-gray-700 cursor-pointer"
                size={24}
                onClick={handleNotificationClick}
              />
              {notifications.length > 0 && (
                <span className="absolute top-0 right-0 bg-red-600 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                  {notifications.length}
                </span>
              )}
              {isNotificationDropdownVisible && (
                <div className="absolute right-0 mt-2 bg-white shadow-lg rounded-lg w-72 p-4 z-20">
                  <h3 className="text-sm font-semibold text-gray-800 mb-2">Notifications</h3>
                  <ul className="space-y-2">
                    {notifications.map((notification) => (
                      <li
                        key={notification.id}
                        className="flex items-start p-2 bg-gray-50 hover:bg-gray-100 rounded-md transition"
                      >
                        <div className="text-gray-600 text-sm">{notification.message}</div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            <div>
      <FaEnvelope
        className="text-gray-500 hover:text-gray-700 cursor-pointer"
        size={24}
        onClick={handleIconClick}
      />
      {showPopup && (
        <ChatWindow receiverId={receiverId} jobId={jobId} onClose={closePopup} />
      )}
    </div>


            <button
              className="ml-4 bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600"
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
        </header>

        <AccountDetailsModal
          isOpen={isAccountModalOpen}
          onClose={() => setAccountModalOpen(false)}
        />

        <main className="p-8 flex-1">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
