import React, { useState, useEffect } from 'react';
import api from './api'; // Centralized API instance

const CreditPage = () => {
  const [credits, setCredits] = useState(0);
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCheckout, setShowCheckout] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
  });
  const [actionMessage, setActionMessage] = useState('');

  useEffect(() => {
    const fetchCreditData = async () => {
      try {
        const response = await api.get('/credits/history');
        setCredits(response.data.credits);
        setHistory(response.data.history);
      } catch (err) {
        setError('Error fetching credit data');
      } finally {
        setLoading(false);
      }
    };

    fetchCreditData();
  }, []);

  const handleCheckoutSubmit = async () => {
    try {
      // API call to initiate payment
      const response = await api.post('/credits/recharge', formData);
      setActionMessage(`Payment initiated. QR code sent to ${formData.email}`);
      setShowCheckout(false);
    } catch (err) {
      setActionMessage(err.response?.data?.msg || 'Error initiating payment');
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-2xl font-bold mb-4">Credit Dashboard</h1>

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <>
          <div className="mb-6">
            <h2 className="text-xl font-semibold">Current Credits</h2>
            <p className="text-lg">Credits: {credits}</p>
          </div>

          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-4">Credit History</h2>
            {history.length > 0 ? (
              <table className="table-auto w-full bg-white shadow-md rounded-lg">
                <thead>
                  <tr>
                    <th className="px-4 py-2">Date</th>
                    <th className="px-4 py-2">Type</th>
                    <th className="px-4 py-2">Amount</th>
                    <th className="px-4 py-2">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {history.map((item) => (
                    <tr key={item._id}>
                      <td className="border px-4 py-2">
                        {new Date(item.date).toLocaleDateString()}
                      </td>
                      <td className="border px-4 py-2">{item.type}</td>
                      <td className="border px-4 py-2">{item.amount}</td>
                      <td className="border px-4 py-2">{item.description || 'N/A'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No credit history available.</p>
            )}
          </div>

          <button
            onClick={() => setShowCheckout(true)}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          >
            Add Credits
          </button>

          {showCheckout && (
            <div className="mt-6 p-6 bg-gray-100 rounded shadow-md">
              <h2 className="text-xl font-semibold mb-4">Checkout</h2>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleCheckoutSubmit();
                }}
              >
                <div className="mb-4">
                  <label className="block mb-2 font-medium">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="w-full border px-4 py-2 rounded"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-2 font-medium">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="w-full border px-4 py-2 rounded"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-2 font-medium">Contact</label>
                  <input
                    type="text"
                    name="contact"
                    value={formData.contact}
                    onChange={handleInputChange}
                    className="w-full border px-4 py-2 rounded"
                    required
                  />
                </div>
                <div className="mb-4">
                  <h3 className="mb-2 font-medium">Scan QR Code to Pay</h3>
                  <img
                    src="/path-to-qr-code.png" // Replace with dynamic QR code generation
                    alt="QR Code"
                    className="w-32 h-32"
                  />
                </div>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                >
                  Confirm Payment
                </button>
              </form>
            </div>
          )}

          {actionMessage && <p className="text-green-500 mt-4">{actionMessage}</p>}
        </>
      )}
    </div>
  );
};

export default CreditPage;
