// src/components/AccountDetailsModal.js
import React from 'react';

const AccountDetailsModal = ({ accountDetails, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded-md shadow-md w-1/3">
        <h2 className="text-xl font-semibold mb-4">Account Details</h2>
        <p><strong>Name:</strong> {accountDetails.name}</p>
        <p><strong>Email:</strong> {accountDetails.email}</p>
        <p><strong>Phone Number:</strong> {accountDetails.phone}</p>
        <p><strong>Company Name:</strong> {accountDetails.companyName}</p>
        <p><strong>Subscription Plan:</strong> {accountDetails.subscriptionPlan}</p>
        <button
          className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default AccountDetailsModal;
