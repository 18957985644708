import React, { useState, useEffect } from 'react';
import api from './api';
import JobApplicants from '../components/JobApplicants';
import ChatWindow from '../components/ChatWindow';
import { Spinner } from '../components/Spinner';

const JobsListPage = () => {
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchJobs = async () => {
      setIsLoading(true);
      try {
        const res = await api.get('/jobs/brand');
        setJobs(res.data);
      } catch (err) {
        setError('Failed to load jobs.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchJobs();
  }, []);

  const handleSelectJob = (job) => {
    setSelectedJob(job);
    setSelectedInfluencer(null); // Reset influencer when job changes
  };

  const handleSelectInfluencer = (influencer) => {
    setSelectedInfluencer(influencer);
  };

  const closeChatWindow = () => {
    setSelectedInfluencer(null);
  };

  return (
    <div className="container mx-auto p-6">
      {/* Error Banner */}
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4 flex justify-between">
          <span>{error}</span>
          <button onClick={() => setError('')} className="font-bold">
            ✕
          </button>
        </div>
      )}

      {/* Loading Spinner */}
      {isLoading && (
        <div className="flex justify-center items-center h-96">
          <Spinner />
        </div>
      )}

      {/* Jobs List */}
      {!isLoading && !error && (
        <div>
          <h1 className="text-4xl font-bold mb-6">Your Jobs</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {jobs.map((job) => (
              <div
                key={job._id}
                onClick={() => handleSelectJob(job)}
                className={`p-4 rounded-lg shadow-lg cursor-pointer transition-transform transform ${
                  selectedJob && selectedJob._id === job._id
                    ? 'bg-blue-100 scale-105'
                    : 'hover:scale-105 hover:bg-gray-100'
                }`}
              >
                <h2 className="text-xl font-semibold">{job.title}</h2>
                <p className="text-gray-600 text-sm mt-2">{job.description.slice(0, 100)}...</p>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Applicants Table */}
      {selectedJob && (
        <div className="mt-6">
          <h2 className="text-2xl font-bold mb-4">{selectedJob.title}</h2>
          <JobApplicants
            jobId={selectedJob._id}
            onSelectInfluencer={handleSelectInfluencer}
          />
        </div>
      )}

      {/* Chat Window in Popup */}
      {selectedInfluencer && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-lg p-6">
            <ChatWindow
              influencer={selectedInfluencer}
              jobId={selectedJob._id} // Pass jobId for chat context
              onClose={closeChatWindow}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default JobsListPage;
