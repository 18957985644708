import axios from 'axios';

// Set up a base instance of axios
const api = axios.create({
    baseURL: 'https://backend-one-henna.vercel.app/api', // Adjust the base URL as needed
});

// Function to set the JWT token in the headers for authorization
export const setAuthToken = token => {
    if (token) {
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete api.defaults.headers.common['Authorization'];
    }
};

// Example of setting the token on initial load if it's available in localStorage
const token = localStorage.getItem('token');
if (token) setAuthToken(token);

// Auth Functions
export const loginUser = async (email, password) => {
    const response = await api.post('/auth/login', { email, password });
    setAuthToken(response.data.token); // Automatically set token after login
    localStorage.setItem('token', response.data.token); // Save token to localStorage
    return response.data;
};

export const registerUser = async (userData) => {
    const response = await api.post('/auth/register', userData);
    return response.data;
};

// Influencer Routes
export const getInfluencerProfile = async () => {
    const response = await api.get('/influencer/profile');
    return response.data;
};

export const updateInfluencerProfile = async (profileData) => {
    const response = await api.put('/influencer/profile', profileData);
    return response.data;
};


export const getAppliedJobs = async () => {
    const response = await api.get('/influencer/applied-jobs');
    return response.data;
};

export const applyForJob = async (jobId) => {
    const response = await api.post(`/influencer/apply/${jobId}`);
    return response.data;
};

export const getAllInfluencerApplications = async () => {
    const response = await api.get('/influencer/applications');
    return response.data;
};

// Jobs Functions
export const getAllJobs = async () => {
    const response = await api.get('/jobs/all');
    return response.data;
};

export const postJob = async (payload) => {
    const response = await api.post('/jobs', payload);
    return response.data;
};

export const postJoblist = async (payload) => {
    const response = await api.post('/jobs/brand', payload);
    return response.data;
  };
  
export const getJobsForBrand = async () => {
    const response = await api.get('/jobs/brand');
    return response.data;
  };
  
export const getJobById = async (jobId) => {
    const response = await api.get(`/jobs/${jobId}`);
    return response.data;
  };



// Super Admin and Admin Functions
export const approveUser = async (userId) => {
    const response = await api.post(`/admin/approve/${userId}`);
    return response.data;
};

// Profile and User-Specific Functions
export const getProfile = async () => {
    const response = await api.get('/users/profile');
    return response.data;
};

export const updateProfile = async (profileData) => {
    const response = await api.put('/users/profile', profileData);
    return response.data;
};

export const getAccountStatus = async () => {
    const response = await api.get('/users/account-status');
    return response.data;
};

// Notifications and Credits Routes
export const getNotifications = async () => {
    const response = await api.get('/notifications');
    return response.data;
};


// Fetch current credits and credit history for a brand
export const getBrandCredits = async () => {
    const response = await api.get('/credits/history');
    return response.data;
};

// Fetch current credits and credit history for an influencer
export const getInfluencerCredits = async () => {
    const response = await api.get('/influencer/credits/history');
    return response.data;
};

// Add credits to a user's account (Admin Only)
export const addCreditsToUser = async (userId, credits) => {
    const response = await api.post('/credits/add', { userId, credits });
    return response.data;
};

// Spend credits for a user (e.g., applying to jobs or posting jobs)
export const spendCredits = async (amount) => {
    const response = await api.post('/credits/spend', { amount });
    return response.data;
};

// Fetch all transactions (spending and adding) for a specific user (Admin view)
export const getAllUserCreditHistory = async (userId) => {
    const response = await api.get(`/credits/history/${userId}`);
    return response.data;
};

// Fetch all brand transactions (Admin view)
export const getAllBrandCreditHistory = async () => {
    const response = await api.get('/credits/brand-history');
    return response.data;
};

// Fetch all influencer transactions (Admin view)
export const getAllInfluencerCreditHistory = async () => {
    const response = await api.get('/credits/influencer-history');
    return response.data;
};




// Send a message between a brand and an influencer
export const sendMessage = async (receiverId, jobId, content) => {
    const response = await api.post('/messages', { receiverId, jobId, content });
    return response.data;
};

// Get all messages for a specific job
export const getJobMessages = async (jobId) => {
    const response = await api.get(`/messages/${jobId}`);
    return response.data;
};

// Get all unread messages for the authenticated user
export const getUnreadMessages = async () => {
    const response = await api.get('/messages/unread');
    return response.data;
};

// Get all messages for a specific job between two users
export const getConversation = async (jobId, userId) => {
    const response = await api.get(`/messages/${jobId}/${userId}`);
    return response.data;
};

// Get all applicants for a specific job
export const getJobApplicants = (jobId) => api.get(`/applications/job/:jobId`);

// Update the status of an application
export const updateApplicationStatus = (applicationId, status) =>
  api.put(`/job/${applicationId}/status`, { status });

export default api;
