import React, { useState, useEffect, useRef } from 'react';
import api from '../api'; // Updated import path

const ChatWindow = ({ influencer, jobId, onClose }) => {
    const [messages, setMessages] = useState([]);
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(true);
    const messagesEndRef = useRef(null);

    useEffect(() => {
        if (!influencer || !influencer._id) {
            console.error('Influencer is undefined or missing _id');
            return;
        }

        const fetchMessages = async () => {
            setLoading(true);
            try {
                const res = await api.get(`/message/${jobId}/${influencer._id}`);
                setMessages(res.data);
                scrollToBottom();
            } catch (error) {
                console.error('Error fetching messages:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchMessages();
    }, [jobId, influencer]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const sendMessage = async (e) => {
        e.preventDefault();
        if (!content.trim()) return;

        try {
            const body = { receiverId: influencer._id, jobId, content };
            const res = await api.post('/message', body);
            setMessages((prevMessages) => [...prevMessages, res.data]);
            setContent('');
            scrollToBottom();
        } catch (error) {
            console.error('Error sending message:', error.response?.data?.msg || error.message);
        }
    };

    if (!influencer || !influencer._id) return null;

    return (
        <div className="fixed bottom-0 left-0 right-0 w-full max-w-md mx-auto bg-gray-100 dark:bg-gray-800 rounded-2xl neo-shadow p-6 space-y-6">
            {/* Chat header */}
            <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                    <div className="w-12 h-12 rounded-full neo-shadow flex items-center justify-center">
                        <span className="text-xl font-semibold text-gray-700 dark:text-gray-300">
                            {influencer.name
                                .split(' ')
                                .map((n) => n[0])
                                .join('')}
                        </span>
                    </div>
                    <h1 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                        Chat with {influencer.name}
                    </h1>
                </div>
                <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                    ✕
                </button>
            </div>

            {/* Chat messages */}
            <div className="h-96 overflow-y-auto neo-inset p-4 rounded-xl space-y-4">
    {loading ? (
        <div>Loading messages...</div>
    ) : Array.isArray(messages) && messages.length === 0 ? (
        <div>No messages yet.</div>
    ) : Array.isArray(messages) ? (
        messages.map((msg) => (
            <div
                key={msg._id}
                className={`flex items-start space-x-2 ${
                    msg.sender && msg.sender._id === influencer._id ? '' : 'justify-end'
                }`}
            >
                {msg.sender && msg.sender._id === influencer._id ? (
                    <>
                        <div className="w-8 h-8 rounded-full neo-shadow flex-shrink-0 flex items-center justify-center">
                            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                                {influencer.name
                                    .split(' ')
                                    .map((n) => n[0])
                                    .join('')}
                            </span>
                        </div>
                        <div className="bg-white dark:bg-gray-700 p-3 rounded-lg neo-shadow max-w-xs">
                            <p className="text-sm text-gray-700 dark:text-gray-300">{msg.content}</p>
                        </div>
                    </>
                ) : (
                    <div className="bg-blue-500 p-3 rounded-lg neo-shadow max-w-xs">
                        <p className="text-sm text-white">{msg.content}</p>
                    </div>
                )}
            </div>
        ))
    ) : (
        <div>Error loading messages.</div>
    )}
    <div ref={messagesEndRef} />
</div>


            {/* Message input */}
            <form onSubmit={sendMessage} className="flex items-center space-x-4">
                <input
                    type="text"
                    placeholder="Type your message..."
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    className="flex-grow p-4 rounded-xl neo-inset bg-transparent text-gray-700 dark:text-gray-300 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none"
                />
                <button
                    type="submit"
                    className="p-4 rounded-xl neo-shadow neo-button focus:outline-none"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-blue-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                    </svg>
                </button>
            </form>
        </div>
    );
};

export default ChatWindow;
