import React, { useState } from "react";
import { postJob } from "./api"; // Import postJob function

const CreateCampaign = () => {
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        category: "",
        productLink: "",
        websiteLink: "",
        influencerTask: "Post about the product on Instagram",
        budget: "",
        liveDate: "",
        contactName: "",
        contactNumber: "",
        contactEmail: "",
    });

    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            ...formData,
            contactDetails: {
                name: formData.contactName,
                phone: formData.contactNumber,
                email: formData.contactEmail,
            },
        };

        try {
            await postJob(payload);
            setSuccess("Campaign created successfully!");
        } catch (err) {
            setError(err.response ? err.response.data.msg : "Error creating campaign");
            console.error("Error Details:", err.response ? err.response.data : err);
        }
    };

    return (
        <div className="container mx-auto max-w-4xl p-6">
            <h1 className="text-2xl font-semibold text-gray-800 mb-4">Create a New Campaign</h1>

            {error && <p className="text-sm text-red-500 mb-3">{error}</p>}
            {success && <p className="text-sm text-green-500 mb-3">{success}</p>}

            <form onSubmit={handleSubmit} className="space-y-6 bg-white p-6 rounded-lg shadow-md">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {/* Campaign Title */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Campaign Title</label>
                        <input
                            type="text"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            className="w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                            placeholder="Enter campaign title"
                            required
                        />
                    </div>

                    {/* Category */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Category</label>
                        <select
                            name="category"
                            value={formData.category}
                            onChange={handleChange}
                            className="w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                            required
                        >
                            <option value="">Select Category</option>
                            <option value="Fashion">Fashion</option>
                            <option value="Technology">Technology</option>
                            <option value="Health">Health</option>
                            <option value="Fitness">Fitness</option>
                        </select>
                    </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {/* Product Link */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Product Link</label>
                        <input
                            type="url"
                            name="productLink"
                            value={formData.productLink}
                            onChange={handleChange}
                            className="w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                            placeholder="Enter product link"
                            required
                        />
                    </div>

                    {/* Website Link */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Website Link</label>
                        <input
                            type="url"
                            name="websiteLink"
                            value={formData.websiteLink}
                            onChange={handleChange}
                            className="w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                            placeholder="Enter website link"
                            required
                        />
                    </div>
                </div>

                {/* Description */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Influencer Task</label>
                    <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        className="w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                        placeholder="Describe the task for the influencer"
                        rows="4"
                        required
                    />
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {/* Budget */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Budget</label>
                        <input
                            type="number"
                            name="budget"
                            value={formData.budget}
                            onChange={handleChange}
                            className="w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                            placeholder="Enter budget"
                            required
                        />
                    </div>

                    {/* Live Date */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Live Date</label>
                        <input
                            type="date"
                            name="liveDate"
                            value={formData.liveDate}
                            onChange={handleChange}
                            className="w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                            required
                        />
                    </div>
                </div>

                {/* Contact Details */}
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Contact Name</label>
                        <input
                            type="text"
                            name="contactName"
                            value={formData.contactName}
                            onChange={handleChange}
                            className="w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                            placeholder="Enter contact name"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Contact Number</label>
                        <input
                            type="tel"
                            name="contactNumber"
                            value={formData.contactNumber}
                            onChange={handleChange}
                            className="w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                            placeholder="Enter contact number"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">Contact Email</label>
                        <input
                            type="email"
                            name="contactEmail"
                            value={formData.contactEmail}
                            onChange={handleChange}
                            className="w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                            placeholder="Enter contact email"
                            required
                        />
                    </div>
                </div>

                {/* Submit Button */}
                <div>
                    <button
                        type="submit"
                        className="w-full rounded-md bg-indigo-600 text-white text-sm font-medium py-2 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Create Campaign
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CreateCampaign;
