import React, { useState, useEffect } from "react";
import { getProfile, updateProfile } from "../api"; // Adjust path if necessary

const Profile = () => {
  const [profile, setProfile] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    subscriptionPlan: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [updatedProfile, setUpdatedProfile] = useState(profile);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profileData = await getProfile(); // Use the `getProfile` function
        setProfile(profileData);
        setUpdatedProfile(profileData);
      } catch (error) {
        console.error("Error fetching profile:", error.response?.data || error.message);
      }
    };

    fetchProfile();
  }, []);

  const handleChange = (e) => {
    setUpdatedProfile({
      ...updatedProfile,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = async () => {
    try {
      await updateProfile(updatedProfile); // Use the `updateProfile` function
      setProfile(updatedProfile);
      setIsEditing(false);
      setSuccessMessage("Profile updated successfully.");
      setTimeout(() => setSuccessMessage(""), 3000); // Clear success message after 3 seconds
    } catch (error) {
      console.error("Error updating profile:", error.response?.data || error.message);
    }
  };

  const handleUpgradeSubscription = () => {
    alert("Upgrade subscription functionality here!");
  };

  return (
    <div className="container mx-auto p-4 sm:p-6">
      <h1 className="text-2xl font-semibold text-gray-800 mb-4">Profile</h1>
      {successMessage && <p className="text-green-600 text-sm mb-4">{successMessage}</p>}

      {isEditing ? (
        <div className="bg-white shadow-md rounded-lg p-6">
          {["name", "email", "phone", "companyName", "subscriptionPlan"].map((field) => (
            <div key={field} className="mb-4">
              <label className="block text-gray-600 text-sm mb-1 capitalize">
                {field.replace(/([A-Z])/g, " $1")}
              </label>
              <input
                type={field === "email" ? "email" : "text"}
                name={field}
                value={updatedProfile[field]}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-md px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>
          ))}
          <div className="flex space-x-4">
            <button
              className="bg-blue-500 text-white text-sm px-4 py-2 rounded-md hover:bg-blue-600"
              onClick={handleSave}
            >
              Save
            </button>
            <button
              className="bg-gray-400 text-white text-sm px-4 py-2 rounded-md hover:bg-gray-500"
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className="bg-white shadow-md rounded-lg p-6">
          {["name", "email", "phone", "companyName", "subscriptionPlan"].map((field) => (
            <p key={field} className="text-sm text-gray-700 mb-2">
              <strong className="capitalize">{field.replace(/([A-Z])/g, " $1")}:</strong>{" "}
              {profile[field]}
            </p>
          ))}
          <div className="flex space-x-4 mt-4">
            <button
              className="bg-blue-500 text-white text-sm px-4 py-2 rounded-md hover:bg-blue-600"
              onClick={() => setIsEditing(true)}
            >
              Edit Profile
            </button>
            <button
              className="bg-green-500 text-white text-sm px-4 py-2 rounded-md hover:bg-green-600"
              onClick={handleUpgradeSubscription}
            >
              Upgrade Subscription
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
