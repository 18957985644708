// src/components/AllJobs.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AllJobs = () => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const token = localStorage.getItem('token'); // Get JWT token from local storage
                const response = await axios.get('https://backend-one-henna.vercel.app/api/jobs/all', {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setJobs(response.data); // Set jobs data to state
                setLoading(false);
            } catch (err) {
                setError('Failed to load jobs');
                setLoading(false);
            }
        };

        fetchJobs();
    }, []);

    if (loading) {
        return <div>Loading jobs...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="container mx-auto p-8">
            <h1 className="text-3xl font-bold mb-6 text-center">Available Jobs</h1>

            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border">
                    <thead>
                        <tr>
                            <th className="py-2 px-4 border-b">Campaign Title</th>
                            <th className="py-2 px-4 border-b">Description</th>
                            <th className="py-2 px-4 border-b">Brand Name</th>
                            <th className="py-2 px-4 border-b">Contact</th>
                            <th className="py-2 px-4 border-b">Applicants</th>
                            <th className="py-2 px-4 border-b">Live Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {jobs.map(job => (
                            <tr key={job._id}>
                                <td className="py-2 px-4 border-b">{job.title}</td>
                                <td className="py-2 px-4 border-b">{job.description}</td>
                                <td className="py-2 px-4 border-b">{job.brand.companyName}</td>
                                <td className="py-2 px-4 border-b">{job.brand.name} ({job.brand.email})</td>
                                <td className="py-2 px-4 border-b">{job.applicants.length}</td>
                                <td className="py-2 px-4 border-b">{new Date(job.liveDate).toLocaleDateString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AllJobs;
