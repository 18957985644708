import React, { useState } from 'react';
import api from '../api'; // Ensure the correct path to `api.js`

const DiscoverInfluencers = () => {
  const [category, setCategory] = useState('');
  const [minFollowers, setMinFollowers] = useState('');
  const [maxFollowers, setMaxFollowers] = useState('');
  const [influencers, setInfluencers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const queryParams = new URLSearchParams({
        ...(category && { category }),
        ...(minFollowers && { minFollowers }),
        ...(maxFollowers && { maxFollowers }),
      });

      const response = await api.get(`/discover-influencers?${queryParams.toString()}`);
      setInfluencers(response.data || []);
    } catch (err) {
      setError('Failed to fetch influencers. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-6">Discover Influencers</h1>

      <form onSubmit={handleSearch} className="mb-6 space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Category
          </label>
          <input
            type="text"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            placeholder="e.g., Fashion"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </div>

        <div className="flex space-x-4">
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700">
              Min Followers
            </label>
            <input
              type="number"
              value={minFollowers}
              onChange={(e) => setMinFollowers(e.target.value)}
              placeholder="e.g., 1000"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          </div>

          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700">
              Max Followers
            </label>
            <input
              type="number"
              value={maxFollowers}
              onChange={(e) => setMaxFollowers(e.target.value)}
              placeholder="e.g., 10000"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          </div>
        </div>

        <button
          type="submit"
          className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700"
        >
          Search
        </button>
      </form>

      {loading && <p>Loading influencers...</p>}
      {error && <p className="text-red-500">{error}</p>}

      {!loading && !error && influencers.length > 0 && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {influencers.map((influencer) => (
            <div
              key={influencer._id}
              className="p-4 border rounded-lg shadow-md bg-white"
            >
              <h3 className="text-lg font-bold">{influencer.name}</h3>
              <p>Platform: {influencer.platforms.join(', ')}</p>
              <p>Followers: {influencer.followers}</p>
              <p>Age: {influencer.age}</p>
              <p>Category: {influencer.categories.join(', ')}</p>
              <button
                className="mt-3 w-full bg-green-600 text-white font-semibold py-2 px-4 rounded-md hover:bg-green-700"
                onClick={() => console.log(`Connect with ${influencer._id}`)}
              >
                Connect
              </button>
            </div>
          ))}
        </div>
      )}

      {!loading && !error && influencers.length === 0 && (
        <p>No influencers found. Try a different search.</p>
      )}
    </div>
  );
};

export default DiscoverInfluencers;
