import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import DashboardLayout from './DashboardLayout';
import Home from './pages/Home';
import DiscoverInfluencers from './pages/DiscoverInfluencers';
import CreateCampaign from './pages/CreateCampaign';
import Profile from './pages/Profile';
import Login from './Login';
import CreditPage from './pages/CreditPage';
import PrivateRoute from './PrivateRoute';
import JobsListPage from './pages/JobsListPage';
import MessagePage from './pages/messagePage';
import Register from './pages/Register';

function App() {
    return (
        <Router>
            <Routes>
                {/* Public Route for Login */}
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />

                {/* Protected Routes */}
                <Route 
                    path="/" 
                    element={
                        <PrivateRoute>
                            <DashboardLayout />
                        </PrivateRoute>
                    }
                >
                    <Route index element={<Home />} />
                    <Route path="discover-influencers" element={<DiscoverInfluencers />} />
                    <Route path="create-campaign" element={<CreateCampaign />} />
                    <Route path="profile" element={<Profile />} />
                    <Route path="credits" element={<CreditPage />} /> {/* Add this route */}
                    <Route path="applied-job" element={<JobsListPage />} /> {/* Add this route */}
                    <Route path="message" element={<MessagePage />} /> {/* Add this route */}



                </Route>
            </Routes>
        </Router>
    );
}

export default App;
