import React from 'react';
import AllJobs from '../components/AllJobs'; // Adjust the path according to your folder structure

const Home = () => {
    return (
        <div className="bg-gray-50 min-h-screen p-6">
            {/* Header Section */}
            <h1 className="text-3xl font-bold text-black mb-6">Welcome to Brand Dashboard</h1>

            {/* Two-column grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                {/* Section 1 */}
                <div className="bg-yellow-400 text-black p-6 shadow-md rounded-md hover:shadow-lg transition-shadow">
                    <h2 className="text-xl font-semibold mb-4">Find Relevant Influencers</h2>
                    <a
                        href="/discover-influencers"
                        className="text-white bg-blue-700 px-4 py-2 rounded hover:bg-blue-800 transition"
                    >
                        Discover Influencers
                    </a>
                </div>

                {/* Section 2 */}
                <div className="bg-blue-700 text-white p-6 shadow-md rounded-md hover:shadow-lg transition-shadow">
                    <h2 className="text-xl font-semibold mb-4">Promote Your Brand</h2>
                    <a
                        href="/create-campaign"
                        className="text-blue-700 bg-yellow-400 px-4 py-2 rounded hover:bg-yellow-500 transition"
                    >
                        Create Campaign
                    </a>
                </div>
            </div>

            {/* Scrollable AllJobs Section */}
            <div className="bg-gray-100 text-black p-6 shadow-md rounded-md">
                <h2 className="text-xl font-semibold mb-4">All Jobs</h2>
                <div className="max-h-80 overflow-y-auto border border-gray-300 rounded-md bg-white p-4">
                    <AllJobs />
                </div>
            </div>
        </div>
    );
};

export default Home;
