import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FaHome, FaSearch, FaBullhorn, FaUser, FaCoins, FaBriefcase, FaEnvelope } from "react-icons/fa";
import logo from "../assets/logo.png"; // Update with the path to your logo file

const Sidebar = () => {
    const location = useLocation(); // For active link highlighting

    const navItems = [
        { to: "/", icon: <FaHome />, label: "Home" },
        { to: "/discover-influencers", icon: <FaSearch />, label: "Discover Influencers" },
        { to: "/create-campaign", icon: <FaBullhorn />, label: "Create Campaign" },
        { to: "/profile", icon: <FaUser />, label: "Profile" },
        { to: "/credits", icon: <FaCoins />, label: "Credits" },
        { to: "/applied-job", icon: <FaBriefcase />, label: "Job Applied" },
        { to: "/message", icon: <FaEnvelope />, label: "Job Message" },

    ];

    return (
        <div className="w-64 h-auto bg-gradient-to-b from-teal-900 to-teal-700 text-gray-200 flex flex-col shadow-lg">
            {/* Logo Section */}
            <div className="flex items-center justify-center p-6 border-b border-teal-600">
                <img src={logo} alt="Brand Logo" className="w-12 h-12 mr-3" />
                <h1 className="text-2xl font-extrabold tracking-wide">Brand</h1>
            </div>

            {/* Navigation Links */}
            <nav className="flex-grow mt-4">
                <ul className="space-y-2">
                    {navItems.map((item) => (
                        <li key={item.to} className="px-4">
                            <Link
                                to={item.to}
                                className={`flex items-center space-x-4 p-3 rounded-lg transition-transform duration-300 ${
                                    location.pathname === item.to
                                        ? "bg-teal-600 text-white scale-105 shadow-md"
                                        : "hover:bg-teal-800 hover:scale-105"
                                }`}
                            >
                                <span className="text-xl">{item.icon}</span>
                                <span className="text-lg font-medium">{item.label}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>

            {/* Footer */}
            <div className="p-4 mt-auto border-t border-teal-600">
                <p className="text-xs text-gray-400 text-center">
                    &copy; 2024 Brand. All rights reserved.
                </p>
            </div>
        </div>
    );
};

export default Sidebar;
