import axios from 'axios';

// Set up a base instance of axios
const api = axios.create({
    baseURL: 'https://backend-one-henna.vercel.app/api', // Adjust the base URL as needed
});

// Function to set the JWT token in the headers for authorization
export const setAuthToken = token => {
    if (token) {
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete api.defaults.headers.common['Authorization'];
    }
};





// Example of setting the token on initial load if available in localStorage
const token = localStorage.getItem('token');
if (token) {
    setAuthToken(token);
} else {
    console.warn('No token found in localStorage');
}

// Auth Functions
export const loginUser = async (email, password) => {
    const response = await api.post('/auth/login', { email, password });
    setAuthToken(response.data.token); // Automatically set token after login
    localStorage.setItem('token', response.data.token); // Save token to localStorage
    return response.data;
};

export const registerUser = async (userData) => {
    const response = await api.post('/auth/register', userData);
    return response.data;
};


// Super Admin and Admin Functions get profile data for any user
export const approveUser = async (userId) => {
    const response = await api.post(`/auth/approve/${userId}`);
    return response.data;
};

export const getProfile = async () => {
    const response = await api.get('/brand/account'); // Adjust route if necessary
    return response.data;
  };
  
  export const updateProfile = async (profileData) => {
    const response = await api.put('/brand/account', profileData); // Adjust route if necessary
    return response.data;
  };




// Influencer Routes
export const getInfluencerProfile = async () => {
    const response = await api.get('/influencer/profile');
    return response.data;
};

export const updateInfluencerProfile = async (profileData) => {
    const response = await api.put('/influencer/profile', profileData);
    return response.data;
};


export const getAppliedJobs = async () => {
    const response = await api.get('/influencer/applied-jobs');
    return response.data;
};

export const applyForJob = async (jobId) => {
    const response = await api.post(`/influencer/apply/${jobId}`);
    return response.data;
};

export const getAllInfluencerApplications = async () => {
    const response = await api.get('/influencer/applications');
    return response.data;
};

// Jobs Functions
export const getAllJobs = async () => {
    const response = await api.get('/jobs/all');
    return response.data;
};

export const postJob = async (payload) => {
    const response = await api.post('/jobs', payload);
    return response.data;
};

export const postJoblist = async (payload) => {
    const response = await api.post('/jobs/brand', payload);
    return response.data;
  };
  
export const getJobsForBrand = async () => {
    const response = await api.get('/jobs/brand');
    return response.data;
  };
  
export const getJobById = async (jobId) => {
    const response = await api.get(`/jobs/${jobId}`);
    return response.data;
  };




export const getAccountStatus = async () => {
    const response = await api.get('/users/account-status');
    return response.data;
};

// Notifications and Credits Routes
export const getNotifications = async () => {
    const response = await api.get('/notifications');
    return response.data;
};


// Fetch current credits and credit history for a brand
export const getBrandCredits = async () => {
    const response = await api.get('/credits/history');
    return response.data;
};

// Fetch current credits and credit history for an influencer
export const getInfluencerCredits = async () => {
    const response = await api.get('/influencer/credits/history');
    return response.data;
};

// Add credits to a user's account (Admin Only)
export const addCreditsToUser = async (userId, credits) => {
    const response = await api.post('/credits/add', { userId, credits });
    return response.data;
};

// Spend credits for a user (e.g., applying to jobs or posting jobs)
export const spendCredits = async (amount) => {
    const response = await api.post('/credits/spend', { amount });
    return response.data;
};

// Fetch all transactions (spending and adding) for a specific user (Admin view)
export const getAllUserCreditHistory = async (userId) => {
    const response = await api.get(`/credits/history/${userId}`);
    return response.data;
};

// Fetch all brand transactions (Admin view)
export const getAllBrandCreditHistory = async () => {
    const response = await api.get('/credits/brand-history');
    return response.data;
};

// Fetch all influencer transactions (Admin view)
export const getAllInfluencerCreditHistory = async () => {
    const response = await api.get('/credits/influencer-history');
    return response.data;
};




// Example API Call: Send a message
export const sendMessage = async (receiverId, jobId, content) => {
    try {
        const response = await api.post('/message', { receiverId, jobId, content });
        return response.data;
    } catch (error) {
        console.error('Error sending message:', error.response?.data || error.message);
        throw error;
    }
};

// Get all messages for a specific job
export const getJobMessages = async (jobId) => {
    const response = await api.get(`/message/${jobId}`);
    return response.data;
};

// Get all unread messages for the authenticated user
export const getUnreadMessages = async () => {
    const response = await api.get('/message/unread');
    return response.data;
};

export const getConversation = async (jobId, userId) => {
    if (!jobId || !userId) {
        console.error('Missing jobId or userId:', { jobId, userId });
        throw new Error('Both jobId and userId are required to fetch conversation.');
    }
    try {
        const response = await api.get(`/message/${jobId}/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching conversation:', error.response?.data || error.message);
        throw error;
    }
};

// Get all applicants for a specific job
//export const getJobApplicants = (jobId) => api.get(`/applications/brand`);




// Example API Call: Update Application Status
export const updateApplicationStatus = async (applicationId, status) => {
    try {
        const response = await api.put(`/${applicationId}/status`, { status });
        return response.data;
    } catch (error) {
        console.error('Error updating application status:', error.response?.data || error.message);
        throw error;
    }
};

// Example API Call: Get Job Applicants
export const getJobApplicants = async (jobId) => {
    try {
        const response = await api.get(`/applications/job/${jobId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching job applicants:', error.response?.data || error.message);
        throw error;
    }
};

export default api;
