import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import api from '../api';

const MessagePage = () => {
    const location = useLocation();
    const jobId = location.state?.jobId; // Retrieve jobId from state
    const [applications, setApplications] = useState([]);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [selectedApplicant, setSelectedApplicant] = useState(null);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!jobId) {
            setError('Invalid Job ID. Please select a valid job.');
            return;
        }

        // Fetch applications and messages for the job
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const [applicationsRes, messagesRes] = await Promise.all([
                    api.get(`/applications/job/${jobId}`),
                    api.get(`/message/${jobId}`)
                ]);
                setApplications(applicationsRes.data);
                setMessages(messagesRes.data);
            } catch (err) {
                setError(err.response?.data?.msg || 'Failed to load data.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [jobId]);

    const handleApplicantSelect = (applicant) => {
        setSelectedApplicant(applicant);
    };

    const handleSendMessage = async () => {
        if (!newMessage.trim() || !selectedApplicant) {
            setError('Please select an applicant and type a message.');
            return;
        }

        try {
            const res = await api.post('/message', {
                receiverId: selectedApplicant.influencer._id,
                jobId,
                content: newMessage,
            });
            setMessages([...messages, res.data]); // Append the new message
            setNewMessage(''); // Clear the input
        } catch (err) {
            setError(err.response?.data?.msg || 'Failed to send the message.');
        }
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div className="text-red-500">{error}</div>;

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-2xl font-bold mb-4">Messages for Job ID: {jobId}</h1>

            {/* Applications List */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
                {applications.map((app) => (
                    <div
                        key={app._id}
                        className={`p-4 rounded-lg shadow ${
                            selectedApplicant?.influencer._id === app.influencer._id ? 'bg-blue-100' : 'bg-white'
                        } cursor-pointer hover:bg-blue-50`}
                        onClick={() => handleApplicantSelect(app)}
                    >
                        <h2 className="font-semibold">{app.influencer.name}</h2>
                        <p className="text-sm text-gray-500">Status: {app.status}</p>
                        <p className="text-xs text-gray-400">Applied: {new Date(app.dateApplied).toLocaleDateString()}</p>
                    </div>
                ))}
            </div>

            {/* Messages Section */}
            <div className="border rounded-lg shadow p-6">
                <h2 className="text-xl font-semibold mb-4">Messages</h2>

                {/* Messages List */}
                <div className="h-64 overflow-y-auto mb-4">
                    {messages.length === 0 ? (
                        <p className="text-gray-500">No messages yet.</p>
                    ) : (
                        messages.map((msg) => (
                            <div
                                key={msg._id}
                                className={`p-3 mb-3 rounded ${
                                    msg.sender._id === selectedApplicant?.influencer._id
                                        ? 'bg-gray-100 text-gray-800'
                                        : 'bg-blue-500 text-white text-right'
                                }`}
                            >
                                <p>{msg.content}</p>
                                <small className="text-xs text-gray-500">
                                    {new Date(msg.date).toLocaleString()}
                                </small>
                            </div>
                        ))
                    )}
                </div>

                {/* New Message Input */}
                <div className="flex items-center">
                    <input
                        type="text"
                        className="flex-grow border border-gray-300 rounded-lg p-2 mr-2"
                        placeholder="Type your message..."
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                    />
                    <button
                        onClick={handleSendMessage}
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                    >
                        Send
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MessagePage;
